
export function Well(params) {
  return (
    <div className="well">
      <div className="container">
        <ElWell
          src={process.env.PUBLIC_URL + "/img/icon/mon1.png"}
          text={"Bitcoin"}
          price={"$ 28,110.1"}
          themeColor={"#E18C44"}
        />
        <ElWell
          src={process.env.PUBLIC_URL + "/img/icon/mon2.png"}
          text={"Ethereum"}
          price={"$ 1,797.68"}
          themeColor={"#627EEA"}
        />
        <ElWell
          src={process.env.PUBLIC_URL + "/img/icon/mon3.png"}
          text={"Binance Coin"}
          price={"$ 337,25"}
          themeColor={"#1E1A15"}
        />
        <ElWell
          src={process.env.PUBLIC_URL + "/img/icon/mon4.png"}
          text={"Bitcoin Cash"}
          price={"$ 133,20"}
          themeColor={"#8BC64B"}
        />
      </div>
    </div>
  );
}
function ElWell({ src, text, price, themeColor }) {
  return (
    <div className='shadow'>
      <div className="ElWell">
        <img className="ElWell_img" src={src} alt="" />
        <div>
          <p style={{ color: themeColor }} className="criptaName">
            {text}
          </p>
          <p className="price">{price}</p>
        </div>
      </div>
    </div>
  );
}
