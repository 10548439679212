
export function Footer(params) {
    return(
        <footer className="footer">
            <div className="container">
                <div className="flex">
                    <div className="logo"><img src="img/logo_main.png" alt=""/></div>
                    <div>
                        <a href="#">О нас</a>
                        <a href="#">Партнеры</a>
                        <a href="#">Наши услуги</a>
                        <a href="#">Клиенты о нас</a>
                        <a href="#">Cripto news</a>
                    </div>
                    <div className="prava">
                        @ все права защищены
                    </div>
                </div>
            </div>
        </footer>
    )
}