import { useEffect, useState } from "react";

export function Header() {
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (menu) {
      document.querySelector("body").classList.add("blockWindow")
    }
    if (!menu) {
      document.querySelector("body").classList.remove("blockWindow")
    }
  }, [menu]);
  return (
    <header className="header">
      <div className="container flex">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/img/logo_main.png"} alt="" />
        </div>
        <div className="mobileBlock flex">
          <div className="menu">
            <nav>
              <a href="">О нас</a>
              <a href="">Партнеры</a>
              <a href="">Наши услуги</a>
              <a href="">Клиенты о нас</a>
              <a href="">Cripto news</a>
            </nav>
          </div>
          <div className="buttonBlock flex end">
            <div className="langs flex">
              <div className="lang active ru">ru</div>
              <div className="lang en">en</div>
            </div>
            <button className="button">Контакты</button>
          </div>
        </div>
        <div className="media_menu">
          <div className={menu ? "burger-menu_button burger-menu_active":"burger-menu_button "}>
            <div onClick={()=>setMenu(!menu)} className="burger-menu_lines"></div>
          </div>
            <nav style={{width:!menu?"0%":"100%"}} className="media_menu_link">
              <a href="">О нас</a>
              <a href="">Партнеры</a>
              <a href="">Наши услуги</a>
              <a href="">Клиенты о нас</a>
              <a href="">Cripto news</a>
            </nav>
        </div>
      </div>
    </header>
  );
}
