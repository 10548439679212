
export function Comments(params) {
  return (
    <section className="comments">
      <div className="container">
        <h2>Отзывы и <br /> пожелания </h2>
        <div className="comments_box">
          <Message
            name="Дамир"
            text="Мне понравился этот сайт для обмена криптовалют. Простой в использовании, быстрый и с низкими комиссиями. Команда поддержки также была очень профессиональна и готова помочь."
            data="2023-03-20"
          />
          <Message
            name="Незнакомец"
            text="Этот сайт для обмена криптовалют прост в использовании и быстрый. Я оцениваю низкие комиссии и внимательность команды поддержки. Единственный недостаток - немного мало доступных вариантов криптовалют для обмена. Но в целом , я доволен своим опытом работы с этим сайтом."
            data="2023-02-11"
          />
          <Message
            name="Отари"
            text="С ребятами работаю давно. Был в их офисе в Грузии, они помогали мне перевести деньги в Дубае. В общем быстро и безопасно. Благодарю, норм сайт."
            data="2023-03-20"
          />
        </div>
      </div>
    </section>
  );
}

function Message(params) {
  return (
    <div className="message_shedow">
      <div className="message">
        <h3>{params.name}</h3>
        <p>{params.text}</p>
        <div className="message_footer">
          <img src="img/stars.png" alt="" />
          <div className="data_box">
            <img src="img/kalend.png" alt="" />
            <p className="data">
                {params.data}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
