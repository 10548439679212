export function Tre(params) {
  return (
    <div className="tre_show">
      <div className="tre">
        <div className="media">
          <img src={params.img} alt="" />
        </div>
        <p>{params.text}</p>
      </div>
    </div>
  );
}
