import { Comments } from "../../components/comments";
import { Service } from "../../components/service";
import { Tre } from "../../components/tre";
import { Well } from "../../components/well";

export function Main() {
  return (
    <main>
      <section className="mainBlock">
        <div className="container">
          <div className="mediaBlock flex bottom">
            <div className="content">
              <div className="info">
                <h1>Сервис по обмену криптовалют</h1>
                <p className="big">
                  Flash
                  <br />
                  Exchange
                </p>
                <p className="mini">
                  Переместим ваш капитал в любую страну присутствия за несколько
                  секунд.
                </p>
                <button className="button">Консультация специалиста </button>
              </div>
            </div>
            <div className="media">
              <img src="img/gerl.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="block2">
        <Well />

        <div className="container">
          <h2>
            Что мы можем <br />
            предложить:
          </h2>
          <div className="listServices flex top">
            <Service
              img="img/service1.svg"
              h3="Подбор недвижимости для покупки."
              text="Услуга предоставляется на территории Грузии и Турции. Мы
                регулярно взаимодействием с большим количеством риелторов и
                застройшиков."
            />
            <Service
              img="img/service2.svg"
              h3="Юридическое сопровождение сделок."
              text="Наши партнеры в Грузии и Турции окажут вам любые необходиые
              услуги, связанные с сопровождением сделок. Включая нотариальные
              услуги."
            />
            <Service
              img="img/service3.svg"
              h3="Подбор автотранспорта, заказ на аукционах в США."
              text="Наше подразделение Flash Avto, сотрудничает с большинством
              аукционеров в США. Мы помогаем перевести деньги на покупку авто,
              осуществляем подбор, оплату выбранного авто до Грузии."
            />
            <Service
              img="img/service4.svg"
              h3="Перестановка денег."
              text="Вы отдаете деньги нашему партнеру в месте нахождения ваших
              средств, и одновременно получаете в стране присутствия
              соответствующую сумму денег в др.валюте."
            />
            <Service
              img="img/service5.svg"
              h3="Оптимизация налогооблогаемых доходов."
              text="Весьма деликатная услуга, которую мы готовы обсудить с нашими
              клиентами индивидуально."
            />
            <Service
              img="img/service6.svg"
              h3="Обмен USDT на USD, RUB, местные валюты."
              text="Вы мгновенно конвертируете имеющуюся у вас валюту (USDT, USD,
                RUB, иную)"
            />
          </div>
        </div>
      </section>

      <section className="block3">
        <div className="container">
          <h2>
            Кому интересны <br />
            наши услуги:
          </h2>

          <div className="interes flex top">
            <Tre
              img="img/uslug1.png"
              text="Если ты планируешь крупную покупку в другой стране
                (недвижимость, авто, оборудование)."
            />
            <Tre
              img="img/uslug2.png"
              text="Если ты путешествуешь по миру и тебе нужны операционные
              средства, доступные в любой стране."
            />
             <Tre
              img="img/uslug3.png"
              text="Если вы хотите оптимизировать ваши налогооблагаемые доходы."
            />
          </div>
        </div>
      </section>

      <section className="block4">
        <div className="container">
          <div className="flex">
            <div className="media">
              <img src="img/ger12.png" alt="" />
            </div>
            <div className="content">
              <h2>
                Свяжитесь <br />с нами
              </h2>
              <div className="kontaktDetail">
                <div className="detail chat">
                  <p>
                    {" "}
                    Обсудите детали необходимой сделки с нашим менеджером,
                    зарезервируйте необходимую сумму денег в выбранном офисе.
                  </p>
                </div>
                <div className="detail">
                  <p>
                    Посетите выбранный офис для закрытия сделки или получите
                    ваши деньги удаленно, не выходя из дома на карту или
                    электронный кошелек.
                  </p>
                </div>
              </div>
              <button className="button">Написать нам</button>
            </div>
          </div>
          <img className="trubka" src="img/trubka.png" alt="" />
        </div>
      </section>

      <section className="block5">
        <div className="container">
          <div className="flex">
            <div className="bigText">Flash Exchange</div>
            <div className="content">
              <h2>Стать партнером:</h2>
              <p>Принять участие в партнерской программе может каждый клиент</p>
              <button  className="button">Cтать партнером</button>
            </div>
            <div className="media">
              <img src="img/cool.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="block6">
        <div className="container">
          <div className="flex">
            <div className="contentLeft">
              <h2>
                Немного <br />о нас
              </h2>
              <p>
                Компания FE основана в 2023 году группой предпринимателей
                релоцировавшихся из страны проживания в новые локации по всему
                миру предоставляет быстрый и удобный сервис по обмену и
                перемещению криптовалюты.
              </p>
              <button className="button">Переместить средства</button>
            </div>
            <div className="contentRight">
              <h3>Наша <br /> миссия</h3>

              <div className="contentRight_info_box">
                <p className="info chat">
                  создание комфортной <br /> инфраструктуры для капитала людей свободно
                  перемещающихся по миру
                </p>
                <p className="info chat">
                  обеспечить людям оперативный доступ к собственным средствам в
                  любой точке мира;
                </p>
                <p className="info chat">
                  перемещать собственный капитал в обход персонификации,
                  подтверждений источников дохода, лимитов, и др. ограничений.
                </p>
              </div>
              <img className="men" src="img/men.png" alt="" />

            </div>
          </div>
        </div>
      </section>
      <Comments/>
    </main>
  );
}
