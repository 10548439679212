import React from 'react';
import ReactDOM from 'react-dom/client';
import { Main } from './pages/main/main';

import "./css/index.css"
import "./css/main.css"
import "./css/media.css"
import "./font/roboto/stylesheet.css"
import "./font/exo2/stylesheet.css"
import { Header } from './components/header';
import { Footer } from './components/footer';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header/>
    <Main/>
    <Footer/>
  </React.StrictMode>
);


