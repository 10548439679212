export function Service(params) {
  return (
    <div className="service">
      <div className="head flex left">
        <img src={params.img} alt="" />
        <h3>{params.h3} </h3>
      </div>
      <p>
        {params.text}
      </p>
    </div>
  );
}
